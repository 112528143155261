/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// ES5 way
// exports.onClientEntry = () => {
// ES6 way
import browserUpdate from 'browser-update';
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    //import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  browserUpdate({
    required: {
        e:-1,
        i:11,
        f:-1,
        o:-1,
        s:-1,
        c:-1,
        samsung: -1,
        vivaldi: -1
    },
    insecure:true
  });
}